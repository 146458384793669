import React, { useState } from "react";
import SingleCourseTemp from "../../academy-comps/academy-single-course-temp/SingleCourseTemp";
import AcademyHeader from "../../academy-comps/academy-header/AcademyHeader";
import InfiniteSlider from "../../../components/ReUsable/infinite-slider/InfiniteSlider";
import "./nika-sandro.css";
import { useTranslation } from "react-i18next";
import AcademyFooter from "../../academy-comps/academy-footer/AcademyFooter";

const NikaSandro = () => {
  const { t } = useTranslation();

  const [syllabus, setSyllabus] = useState([
    {
      name: "ლექცია 1",
      theme: (
        <ul>
          <li>სოციალური მედიისა და ციფრული რეკლამების მნიშვნელობა</li>
          <li>- ძირითადი პრინციპები სოც–მედიაში პირველი ნაბიჯებისთვის</li>
          <li>- სოც–მედია პლატფორმების განხილვა</li>
          <li>- სოც-მედია პლატფორმების და მათი ალგორითმების განხილვა</li>
          <li>- სოციალური მედიისა და ციფრული რეკლამების მნიშვნელობა</li>
          <li>- ციფრული მარკეტინგის მოქმედება ქართულ ბაზარზე</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 2",
      theme: (
        <ul>
          <li>FB ბიზნეს მენეჯერის პლატფორმა</li>
          <li>- FB ბიზნეს მენეჯერის პლატფორმა</li>
          <li>- ბიზნეს მენეჯერის სრული მოწესრიგება</li>
          <li>- გადახდის მეთოდების დამატება</li>
          <li>- როგორ ვიურთიერთოთ FB დამხმარე ცენტრთან</li>
          <li>- როგორ დავიზღვიოთ თავი არასასურველი ბლოკისგან</li>
          <li>
            - ბიზნეს მენეჯერის სწორი მართვა სხვადასხვა სიტუაციების შესაბამისად
          </li>
        </ul>
      ),
    },
    {
      name: "ლექცია 3",
      theme: (
        <ul>
          <li>FB სარეკლამო მენეჯერის გაცნობა</li>
          <li>- განსხვავება სარეკლამო მენეჯერსა და ბუსტს შორის</li>
          <li>- სარეკლამო კამპანიების სახეობები და გამოყენების მეთოდები</li>
          <li>- სარეკლამო მენეჯერის დეტალები, რომლებიც არ უნდა გამოგვრჩეს</li>
          <li>- კამპანიების აწყობა, ედიტინგი</li>
          <li>- კამპანიების მუდმივი მართვის მექანიზმები</li>
          <li>- აუდიტორიის შექმნის პლატფორმა</li>
          <li>- აუდიტორიის შერჩევის მექანიზმების გაცნობა</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 4",
      theme: (
        <ul>
          <li>ქოფირაითინგი სოც. მედია მარკეტერისთვის</li>
          <li>- რას ნიშნავს სწორი კონტენტი და როგორ შევქმნათ ის?</li>
          <li>- როგორ შევარჩიოთ კონტენტის მიმდინარეობები?</li>
          <li>- როგორ შევარჩიოთ საკომუნიკაციო ტონი?</li>
          <li>
            - ქოფის ვარიაციები და მათი კონტენტზე მორგება; ემოჯებისა და
            ჰეშთეგების გამოყენება; მაგალითების განხილვა
          </li>
          <li>
            - „გრამატიკა ყველაფერი არაა, მაგრამ საჭიროა“ - სად და როგორ
            გადავამოწმოთ ჩვენი ტექსტები
          </li>
          <li>
            - ვიზუალისა და ტექსტის თანხვედრის მნიშვნელობა; ვიზუალის ტექსტზე
            მუშაობა
          </li>
          <li>- ორენოვან ქოფიზე მუშაობის პრინციპები (ქართულ-ინგლისური)</li>
          <li>- პრაქტიკული დავალებები</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 5",
      theme: (
        <ul>
          <li>ციფრული მარკეტინგული სტრატეგიის გრაფიკული მხარე</li>
          <li>- რა არის გრაფიკული დიზაინი</li>
          <li>- ვიზუალური კომუნიკაციის მაგალითები</li>
          <li>- რა როლი აქვს მარკეტინგში/ედვერთაიზინგში</li>
          <li>
            - დიზაინერის მოვალეობები -რა განსხვავებაა გრაფიკულ დიზაინსა და
            ედვერთაიზინგ დიზაინს შორის
          </li>
          <li>- რას ვითვალისწინებთ ციფრული რეკლამის ვიზუალისთვის?</li>
          <li>- გრაფიკული დიზაინის ძირითადი პრინციპები</li>
          <li>- რა არის ბრენდბუქი/რას გულისხმობს მისი დაცვა</li>
          <li>- 3:30:3 წესი</li>
          <li>- რა ხდის ბრენდს დასამახსოვრებელს?</li>
          <li>- ფერების ფსიქოლოგია</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 6",
      theme: (
        <ul>
          <li>სარეკლამო მენეჯერის დეტალები</li>
          <li>- Meta Ads-ის შექმნა & გამოყენება</li>
          <li>- სარეკლამო ალგორითმის განხილვა</li>
          <li>- რეკლამა Meta Ads-ში</li>
          <li>
            - რეკლამების მრავალფეროვნება, მათი დანიშნულება და კლასიფიკაცია
          </li>
          <li>- რეკლამის ბიუჯეტირება და ოპტიმიზაცია</li>
          <li>- Billing-ი და გადახდის სისტემა</li>
          <li>
            - სარეკლამო ანგარიშის ლეველები და მათი მაღალ დონეზე შენარჩუნების
            მეთოდები
          </li>
          <li>- ობჯექთივების სწორი სინქრონი</li>
          <li>
            - Meta Ads Manager-ის სარეკლამო ინსტრუმენტების ძირითადი ფუნქციონალი
            და (ODAX)- ის განახლება
          </li>
          <li>
            - აუქციონის მნიშვნელობა რეკლამის მუშაობაში (Vickrey-Clarke-Groves
            Auction)
          </li>
          <li>- სამიზნე აუდიტორიაზე გასვლის მეთოდები</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 7",
      theme: (
        <ul>
          <li>საქართველოს ბანკის სარეკლამო მენეჯერი:</li>
          <li>
            - ინტერფეისის განხილვა და როგორ მოვიწვიოთ ადმინი და დავეთანხმოთ
            მოწვევას
          </li>
          <li>- შეთავაზების შექმნა და რეკლამის გაშვება</li>
          <li>- ანალიტიკა და შედეგების დათვლის პროცესი</li>
          <li>- შეთავაზების კონტენტის გაიდლაინები</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 8",
      theme: (
        <ul>
          <li>
            მომხმარებლის ფსიქოლოგია, ვინ ყიდულობს ჩვენს პროდუქტს ან სერვისს და
            როგორ მივაწოდოთ სწორად
          </li>
          <li>- როგორ შეიცვალა სოც ქსელები კოვიდის შემდგომ</li>
          <li>- სოც მედია მენეჯერის როლი ციფრულ სამყაროში</li>
          <li>
            - ვისზე უნდა გავთვალოთ ჩვენი პროდუქტი, ვინ არის ჩვენი მიზნობრივი
            აუდიტორია
          </li>
          <li>
            - რომელი კამპანიები არ არის მომგებიანი ჩვენი სერვისის ან
            პროდუქტისთვის
          </li>
          <li>- როგორ შევაფასოთ ჩვენი შედეგები</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 9",
      theme: (
        <ul>
          <li>Google Business-ის პლატფორმის გაცნობა და Google Ads</li>
          <li>- გუგლ ბიზნესის რეგისტრაცია</li>
          <li>- გუგლ ბიზნესის ვერიფიკაცია</li>
          <li>- -გუგლ ბიზნესის საბაზისო მოწესრიგება</li>
          <li>- პოსტები და კონტენტი გუგლ ბიზნესში</li>
          <li>- რეკლამისთვის შესაფერისი საკვანძო სიტყვების მოძიება</li>
          <li>- საკვანძო სიტყვების ანალიტიკა</li>
          <li>- რეკლამის სწორი ბიუჯეტით ჩაშვება</li>
          <li>- რეკლამის მაკონტროლებელი მექანიზმის შესწავლა</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 10",
      theme: (
        <ul>
          <li>რა უნდა ვიცოდეთ SEO-ზე</li>
          <li>- რას წარმოადგენს საძიებო სისტემის ოპტიმიზაცია?</li>
          <li>
            - საჭირო არის თუ არა ვებსაიტის თავად ასახვა Google-ის საძიებო
            სისტემაზე?
          </li>
          <li>- ვებსაიტის აუდიტი და ანალიზი SEO ოპტიმიზაციისათვის</li>
          <li>- უნიკალური კონტენტის მნიშვნელობა ვებსაიტზე</li>
          <li>
            - რას წარმოადგენს Backlink-ები და მათი მნიშვნელობა ვებსაიტის
            წარმატებისთვის საძიებო სისტემაზე
          </li>
          <li>- ქივორდ რისერჩი და SEO ოპტიმიზაციის ეტაპები</li>
          <li>- Google Trend-ი და ქივორდების ვერიფიკაცია</li>
          <li>- ფასიანი და უფასო ინსტრუმენტები SEO ოპტიმიზაციისთვის</li>
          <li>- შეჯამება, როგორ შევაფასოთ ვებსაიტი SEO აუდიტის მიხედვით</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 11",
      theme: (
        <ul>
          <li>სხვადასხვა პლატფორმების მოწესრიგება რეკლამირება</li>
          <li>- ლინკდინის ბიზნეს გვერდის შექმნა, მოწესრიგება, მართვა</li>
          <li>- ლინკდინის რეკლამირება</li>
          <li>- ინსტაგრამის შექმნა, მოწესრიგება, ბიზნეს სტილზე გადაყვანა.</li>
          <li>- ინსტაგრამის რეკლამირება(მე5 შეხვედრის დეტალების ჩაშლა)</li>
          <li>- ტვიტერის ბიზნეს გვერდის შექმნა, მოწესრიგება, მართვა</li>
          <li>- ტვიტერის რეკლამირება</li>
          <li>- იანდექს ედვერთაიზინგი</li>
          <li>- იანდექსის სამართავი პლატფორმის გამართვა</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 12",
      theme: (
        <ul>
          <li>კონტენტის ფოტო/ვიდეო ნაწილი სოც-მედია პლატფორმებისთვის</li>
          <li>- ფოტოგრაფიის საბაზისო ნაწილი</li>
          <li>- ფოტო სტუდიის გამართვა</li>
          <li>- ფოტო გადაღების დაგეგმვა, მოდელებისა და აქსესუარების შერჩევა</li>
          <li>- ვიდეო გადაღებისთვის საჭირო აპარატურა</li>
          <li>- ვიდეო გადაღების ეტაპები და წინა მოსამზადებელი პროცესი</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 13",
      theme: (
        <ul>
          <li>ჩაშვებული რეკლამების კორექტირება და შემდგომი ანალიტიკა</li>
          <li>- სარეკლამო მენეჯერის ანალიტიკის გამოყენება</li>
          <li>- როგორ განვსაზღვროთ რეკლამის წარმატებულობა</li>
          <li>- რეკლამის კორექტირება ანალიზის საფუძველზე</li>
          <li>- ანალიტიკისთვის საჭირო პროგრამული უზრუნველყოფა</li>
          <li>- ექსელი ანალიტიკისთვის საბაზისოდ</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 14",
      theme: (
        <ul>
          <li>მზადება ტესტისთვის</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 15",
      theme: (
        <ul>
          <li>მზადება ტესტისთვის</li>
        </ul>
      ),
    },
    {
      name: "ლექცია 16",
      theme: (
        <ul>
          <li>მზადება ტესტისთვის</li>
        </ul>
      ),
    },
  ]);

  return (
    <div>
      <AcademyHeader />
      <SingleCourseTemp
        syllabus={syllabus}
        courseName={t("nikaSandroName")}
        lecturer1={t("nika")}
        lecturer2={t("sandro")}
        duration1={`2 ${t("months")}`}
        duration2={`16 ${t("lecture")}`}
        schedule1={t("nikaSandroTime1")}
        schedule2={"(19:30)"}
        schedule3={t("nikaSandroTime2")}
        schedule4={"(19:30)"}
        places={t("nikaSandroPlace")}
        price={t("nikaSandroPrice")}
        img={"../img/nika-sandro-single.jpg"}
      />
      <InfiniteSlider />
      <AcademyFooter />
    </div>
  );
};

export default NikaSandro;
